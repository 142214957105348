import { styled } from "goober";
import {
  desktop,
  mobile,
  mobileMedium,
  tablet,
  tabletLarge,
  tabletMedium,
} from "clutch/src/Style/style.mjs";

export const PageContainer = styled("div")`
  --section-gap: var(--sp-30);
  --content-max-width: min(1200px, 100vw);

  width: 100%;
  margin: 0 auto;

  footer {
    margin-top: var(--sp-24);
  }

  ${mobile} {
    --section-gap: var(--sp-16);
  }
`;

const Section = styled("section")`
  width: 100%;
  margin-inline: auto;

  ${desktop} {
    padding-inline: var(--sp-12);
  }

  ${mobile} {
    padding-inline: var(--sp-6);
  }
`;

export const MissionSection = styled(Section)<{ $bgImage: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${"" /* due to design */}
  height: 768px;

  color: var(--white);

  background: radial-gradient(
      116.8% 116.8% at 50% 0%,
      rgba(0, 0, 0, 0) 41.99%,
      var(--shade9) 85.51%
    ),
    url(${(props) => props.$bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: var(--content-max-width);
    margin: var(--sp-24) auto;
    padding: 0px 5%;

    text-align: center;

    h1 {
      font-weight: 500;
      font-size: var(--sp-3);
      margin-bottom: var(--sp-6);
      text-transform: uppercase;
    }

    p {
      font-weight: normal;
      font-size: var(--sp-12);
      line-height: var(--sp-16);
      letter-spacing: -0.009em;

      ${tabletMedium} {
        font-size: var(--sp-10);
        line-height: var(--sp-12);
      }

      ${mobile} {
        font-size: var(--sp-8);
        line-height: var(--sp-11);
      }
    }
  }
`;

export const MainSections = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--section-gap);

  margin-bottom: var(--section-gap);

  overflow: hidden;

  section {
    max-width: var(--content-max-width);
  }
`;

export const CollageSection = styled(Section)`
  --spacing: var(--sp-4);

  display: flex;
  gap: var(--spacing);

  ${"" /* due to design */}
  height: 522px;

  div {
    flex: 1 1 0;

    display: flex;
    gap: var(--spacing);

    border-radius: var(--br-lg);

    overflow: hidden;
  }

  div.row {
    flex-direction: row;
  }

  div.col {
    flex-direction: column;
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  ${mobile} {
    display: block;

    height: auto;
    padding: var(--spacing);

    white-space: nowrap;
    overflow-x: auto;

    div {
      flex: unset;

      display: inline-flex;
    }

    div.col {
      flex-direction: row;
    }

    div.item {
      ${"" /* due to design */}
      width: 350px;
      min-width: 220px;
      height: 450px;
    }
  }

  ${mobileMedium} {
    div.item {
      ${"" /* due to design */}
      width: 220px;
      min-width: 220px;
      height: 300px;
    }
  }
`;

export const PillarsSection = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-16);

  h2 {
    max-width: 590px;

    color: var(--white);

    ${mobile} {
      max-width: 470px;

      font-size: var(--sp-6e);
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: var(--sp-6);

    li {
      display: flex;
      flex-direction: column;
      gap: var(--sp-4);

      padding: var(--sp-6);

      border: 1px solid rgba(227, 229, 234, 0.15);
      border-radius: var(--sp-2);

      h4 {
        color: var(--white);
      }

      p {
        color: var(--shade1);

        font-size: var(--sp-3);
        font-weight: 700;
      }
    }
  }
`;

export const GlobeSection = styled(Section)`
  position: relative;

  display: flex;

  ${"" /* due to design */}
  min-height: 500px;

  color: var(--white);

  z-index: 1;

  & > div.text-block {
    position: relative;

    width: 50%;

    z-index: 2;

    ${tablet} {
      width: 60%;
    }

    ${mobileMedium} {
      width: 100%;
      margin-top: auto;
    }
  }

  h2 {
    margin-bottom: var(--sp-4);

    font-size: var(--sp-3);
    font-weight: 600;
    line-height: var(--sp-7);
    text-transform: uppercase;

    ${mobileMedium} {
      font-weight: 700;
      line-height: var(--sp-5);
    }
  }

  p {
    margin-bottom: var(--sp-10);

    font-weight: 400;
    font-size: var(--sp-7);
    line-height: var(--sp-10);

    ${mobileMedium} {
      margin-bottom: var(--sp-7);

      font-weight: 500;
      font-size: var(--sp-4);
      line-height: var(--sp-7);
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  & > div.globe {
    position: absolute;
    top: 0;
    left: 8%;

    width: 100%;

    z-index: 0;

    & > div {
      right: calc(-8% - var(--sp-30));

      ${desktop} {
        right: 0px;
      }

      ${tabletLarge} {
        right: calc(-6.25% - 200px);
      }

      ${mobile} {
        right: calc(-4% - 270px);
      }

      ${mobileMedium} {
        right: calc(-4% - 320px);
        bottom: unset;
      }
    }
  }
`;

export const JoinSection = styled(Section)`
  padding-bottom: var(--section-gap);

  color: var(--white);
  text-align: center;

  background: linear-gradient(
    102.98deg,
    rgb(255, 17, 45) -0.14%,
    rgb(207, 16, 80) 100%
  );

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-6);

    ${"" /* due to design */}
    max-width: 1044px;
    margin: 0 auto;
    padding: var(--section-gap) 0;
  }

  h3 {
    font-weight: 500;
    font-size: var(--sp-3);
    text-transform: uppercase;
  }

  p {
    font-weight: 400;
    font-size: var(--sp-7);
    line-height: var(--sp-10);
  }

  a {
    padding: var(--sp-4) var(--sp-6_5);

    color: #dd344a;
    text-align: center;

    background: var(--white);
    border-radius: var(--sp-2);

    font-size: var(--sp-4);
    font-weight: 700;
    line-height: var(--sp-7);

    transition: all 0.2s ease-in-out !important;

    &:hover {
      color: var(--white);
      background: #dd344a;
    }

    ${mobile} {
      width: 100%;
    }
  }
`;

export const EncroachingImageSection = styled(Section)`
  max-width: var(--content-max-width);
  margin: 0 auto;
  margin-top: calc(var(--section-gap) * -1);

  ${mobileMedium} {
    padding-inline: 0 !important;
  }

  img {
    width: 100%;
    height: auto;

    border-radius: var(--sp-2);

    object-fit: cover;

    ${mobileMedium} {
      border-radius: 0;
    }
  }
`;
